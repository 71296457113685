import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import SEO from '../components/base/Seo';

const NotFoundPage = () => {
  const {
    wp: {
      options: {
        globalOptions: { notFoundTitle, notFoundBody, returnHomeLabel },
      },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        options {
          globalOptions {
            notFoundTitle
            notFoundBody
            returnHomeLabel
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="404: Not found" />

      <section
        className="flex flex-col items-center text-white bg-cover pb-44"
        style={{
          backgroundImage:
            'linear-gradient(159.3deg, #F7E69A -48.7%, #EE7272 80.57%)',
        }}
      >
        <img
          src="/images/404.svg"
          alt="404"
          className="w-full h-auto mb-12"
          style={{ maxHeight: '50vh' }}
        />

        <div className="flex flex-col items-center u-container">
          <h1
            className="u-h3 mb-2.5 text-center"
            dangerouslySetInnerHTML={{ __html: notFoundTitle }}
          />
          <div
            className="max-w-full mb-8 text-lg leading-relaxed text-center w-120"
            dangerouslySetInnerHTML={{ __html: notFoundBody }}
          />
          <Link to="/" className="rounded u-btn u-btn--primary">
            {returnHomeLabel}
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
